<template>
    <div class="RushHour">
        <div class="wraptitleRushhour">
            <span class="TitleCard">
                {{ $t('Report.Attribute').toUpperCase() }}
            </span>
        </div>
        <div id="chart">
            <apexchart type="bar" height="350" :options="chartOptions" :series="attributeSeries"></apexchart>
        </div>
    </div>
</template>
<script>
import * as moment from 'moment-timezone';
import { Api } from '@/api/index';

moment.tz.setDefault('Asia/Ho_Chi_Minh');
moment.locale('vi');

const attributeReportAPI = Api.get('attributeReport');

export default {
    name: 'AttributeSection',
    components: {},
    props: {
        dateRange: {
            type: Object,
        },
        choosenGroupID: {
            type: String,
        },
    },
    mounted() {
        console.log(this.choosenGroupID);
        this.dateStart = this.dateRange.dateStart;
        this.dateEnd = this.dateRange.dateEnd;
        this.getAttributeReport(this.choosenGroupID, this.dateStart, this.dateEnd);
    },
    watch: {
        dateRange: {
            handler: function(val) {
                console.log(this.dateRange);
                this.dateStart = val['dateStart'];
                this.dateEnd = val['dateEnd'];
                this.getAttributeReport(this.groupId, this.dateStart, this.dateEnd);
            },
            deep: true,
        },
        choosenGroupID(groupId) {
            this.groupId = groupId;
            console.log(groupId);
            this.getAttributeReport(this.groupId, this.dateStart, this.dateEnd);
        },
    },
    data() {
        let self = this;
        return {
            dateStart: '',
            dateEnd: '',
            groupId: '',
            attributeSeries: [
                {
                    name: this.$t('Report.GenderMale'),
                    data: [0, 0, 0],
                },
                {
                    name: this.$t('Report.GenderFemale'),
                    data: [0, 0, 0],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                colors: ['#3EC1D3', '#FF8C94'],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0,
                            },
                        },
                    },
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 0,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900,
                                },
                            },
                        },
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: [
                        this.$t('Report.AgeChildren'),
                        this.$t('Report.AgeAdult'),
                        this.$t('Report.AgeElderly'),
                    ],
                },
                legend: {
                    position: 'bottom',
                    offsetY: 10,
                },
                fill: {
                    opacity: 1,
                },
            },
        };
    },
    methods: {
        getAttributeReport(groupId, dateStart, dateEnd) {
            console.log('start get attribute report');
            attributeReportAPI
                .get(groupId, dateStart, dateEnd)
                .then(response => {
                    console.log(response.data);

                    this.attributeSeries = [
                        {
                            name: this.$t('Report.GenderMale'),
                            data: [...response.data.male],
                        },
                        {
                            name: this.$t('Report.GenderFemale'),
                            data: [...response.data.female],
                        },
                    ];
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};
</script>
<style lang="scss">
.RushHour {
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    height: 582px;
    padding: 24px;
    position: relative;
    .wraptitleRushhour {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .TitleCard {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1f1f1f;
        }
    }
    .HiddenTrial {
        width: 150px;
        height: 50px;
        background-color: #ffffff;
        position: absolute;
        bottom: 13px;
        left: 26px;
        z-index: 2;
    }
}
</style>
